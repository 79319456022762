import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'components/Layout'
import Footer from 'components/Footer'
import Header from 'components/Header'

import siteMetaData from '../query/siteMetaData'

export default ({ data }) => {
  const { email, phone } = siteMetaData()

  return (
    <Layout pageTitle='Personuppgiftspolicy' siteImage={data.header}>
      <Header fluidImage={data.header.childImageSharp.fluid} />
      <oma-grid-row>
        <section className='section'>
          <h1 className='heading page__heading'>
            Person&shy;uppgifts&shy;policy
          </h1>
          <p>
            Din integritet är viktig för oss och vi ansvarar för den personliga
            information som du förser oss med. Denna policy beskriver hur vi
            behandlar, lagrar och hanterar dina personuppgifter dels när du är
            kund hos oss, dels när du söker arbete hos oss.
          </p>
          <h2>Ansvarig för hantering av personuppgifter</h2>
          <p>
            Stordjursveterinärerna i Varberg AB är personuppgiftsansvarig för
            den behandling av personuppgifter som beskrivs i den här policyn.
          </p>
          <p>
            Telefonnummer: <oma-link to={phone} class='' />
            <br /> Adress: Källebergsvägen 14, 432 91 Varberg
            <br /> <oma-link to={email} class='' />
          </p>
          <h2>Hur får vi tillgång till personuppgifterna?</h2>
          <p>
            Utöver de uppgifter du själv lämnar till oss i samband med att du
            blir kund hos oss, kontaktar oss eller söker anställning hos oss,
            kan vi också komma att samla in personuppgifter från en tredje part.
            De uppgifter vi samlar in från tredje part är adress- och
            telefonuppgifter från offentliga register för att vara säkra på att
            vi har rätt adressuppgifter till dig. I vissa fall även uppgifter om
            kreditvärdighet från kreditvärderingsinstitut, banker eller
            upplysningsföretag.
          </p>
          <h2>Vilka personuppgifter behandlar vi?</h2>
          <p>
            Följande kategorier av personuppgifter kan vi komma att behandla:
          </p>
          <ul>
            <li>
              Kontaktuppgifter såsom namn, adress, e-postadress, telefonnummer
            </li>
            <li>Identitetsuppgifter såsom personnummer, organisationsnummer</li>
            <li>
              Identitetsuppgifter för ert djur såsom namn, ras, id-nummer,
              ålder, födelsedatum, kön
            </li>
            <li>Känsliga uppgifter såsom hälsouppgifter gällande ert djur</li>
            <li>
              Ekonomiska uppgifter såsom bankkontonummer och andra
              bankrelaterade uppgifter
            </li>
            <li>
              Arbetsrelaterade uppgifter såsom anställningsuppgifter,
              ansökningar, CV, personligt brev
            </li>
          </ul>
          <h2>Hur behandlar vi dina personuppgifter?</h2>
          <p>
            Vi använder dessa uppgifter för att kunna tillhandahålla tjänster
            och fullfölja våra förpliktelser mot dig, samt för att följa lag-
            och myndighetskrav. Vår utgångspunkt är att inte behandla fler
            personuppgifter än vad som behövs, och vi strävar alltid efter att
            använda de minst integritetskänsliga uppgifterna.
          </p>
          <p>
            Nedan följer information om vilka personuppgiftsbehandlingar som
            finns.
          </p>
          <h3>Tillhandahålla och fullgöra avtal om tjänster/varor</h3>
          <p>
            Vi behandlar personuppgifter för att kunna tillhandahålla
            tjänster/varor till dig. Vi behandlar personuppgifter för vår
            administration och fakturering i journalprogrammet Vetmanager, för
            kreditupplysningsändamål, för att hantera klagomål och
            reklamationer, för att hjälpa dig vid frågor samt i övrigt för att
            tillvarata våra rättigheter och fullgöra våra skyldigheter.
            Personuppgifter vi hanterar i denna behandling är kontaktuppgifter,
            identitetsrelaterade personuppgifter, känsliga uppgifter samt
            ekonomiska personuppgifter.
          </p>
          <h3>Bokföring</h3>
          <p>
            Vi behandlar dina personuppgifter i syfte att kunna fullgöra
            lagstadgade skyldigheter, t ex bokföringslagens krav på arkivering
            av räkenskapsmaterial. Personuppgifter vi hanterar i denna
            behandling är kontaktuppgifter, identitetsrelaterade personuppgifter
            samt ekonomiska personuppgifter.
          </p>
          <h3>Marknadsföring</h3>
          <p>
            Vi behandlar personuppgifter för att möjliggöra marknadsföring av
            varor/tjänster till dig och att möjliggöra utskick med information
            om företaget. Vidare även att möjliggöra inbjudan till evenemang
            inom området för ditt intresse. Personuppgifter vi hanterar i denna
            behandling är kontaktuppgifter.
          </p>
          <h3>Inför anställning</h3>
          <p>
            Vi behandlar dina personuppgifter i samband med att du söker lediga
            tjänster eller anmäler ditt intresse för anställning hos oss.
            Bolaget behandlar dina personuppgifter för att bedöma din ansökan
            och genomföra rekryteringsprocessen. Personuppgifter vi hanterar i
            denna behandling är kontaktuppgifter och identitetsrelaterade
            personuppgifter.
          </p>
          <h2>
            Vilken rättslig grund har vi för våra personuppgiftsbehandlingar?
          </h2>
          <p>
            Vi behandlar dina personuppgifter för att kunna administrera och
            tillhandahålla avtalad tjänst/vara. När det gäller
            personuppgiftsbehandling för att fullgöra krav som exempelvis lagen
            om journalföring, bokföringslagen eller skattelagstiftningen är den
            rättsliga grunden för behandling rättslig förpliktelse.
          </p>
          <p>
            För behandlingarna marknadsföring och inför anställning är den
            rättsliga grunden vårt berättigade intresse. Detta innebär att vi
            anser att våra intressen att behandla dina personuppgifter för dessa
            ändamål som listas ovan överväger det integritetsintrång som du
            utsätts för med anledning av behandlingen. Denna bedömning har
            gjorts särskilt med beaktande av att vi anser att behandlingen
            kommer att vara fördelaktig för dig.
          </p>
          <p>
            När det gäller personuppgifter i samband med arbetsansökningar som
            inte har något samband med en rekryteringsprocess eller avslutad
            rekryteringsprocess så kommer vi att spara dina personuppgifter för
            eventuellt framtida rekryteringsbehov endast om du har samtyckt
            särskilt till detta.
          </p>
          <h2>Hur länge lagrar vi dina personuppgifter?</h2>
          <p>
            Så länge du fortsätter att vara kund hos oss tas inga journaler
            eller andra personuppgifter bort enligt lagen om journalföring. När
            du inte varit aktiv på kliniken i tio år tas samtliga uppgifter bort
            om dig och dina djur. Vi sparar uppgifter under denna tid för att
            djur lever länge och för att vi ska kunna ge dig och dina djur
            möjlighet till information från tidigare besök om ni återvänder till
            oss. Om du vill bli borttagen efter fem års inaktivitet (fem år är
            lagstadgat krav) går detta att göra genom att logga in med BankID i
            Vetmanager, se deras <a href='https://www.vetmanager.se'>hemsida</a>{' '}
            för vidare information. Du bör även kontakta oss för att säkerställa
            att alla uppgifter tas bort.
          </p>
          <p>
            Vissa personuppgifter sparas även under en längre tid för att
            exempelvis uppfylla krav från bokföringslagen och
            skattelagstiftningen. När ändamålen med behandlingarna är uppfyllda
            och lagringstiden löpt ut raderas dina personuppgifter på ett säkert
            sätt eller anonymiseras så att det inte längre går att koppla dem
            till dig.
          </p>
          <h2>Vilka delar vi personuppgifter med?</h2>
          <p>
            Vår utgångspunkt är att inte lämna ut registrerades personuppgifter
            till utomstående om den registrerade inte har samtyckt till det
            eller om det inte är nödvändigt för att uppfylla våra förpliktelser
            enligt avtal eller lag. I de fall vi lämnar ut personuppgifter till
            utomstående säkerställer vi att personuppgifterna behandlas på ett
            betryggande sätt.
          </p>
          <h3>Tjänsteleverantörer</h3>
          <p>
            För att uppfylla ändamålen med vår behandling av dina
            personuppgifter och för att uppfylla de krav som vi är skyldiga
            såsom djurhälsopersonal och företag, delar vi personuppgifter med
            företag som tillhandahåller tjänster till oss.
          </p>
          <p>
            För information om hur journalprogrammet Vetmanager hanterar
            uppgifter enligt GDPR eller besök deras{' '}
            <a href='https://vetmanager.se/main/gdpr_vetmanager'>hemsida</a>.
          </p>
          <p>
            Vid eventuell remiss av ditt djur till annan klinik överförs
            journalen till den andra kliniken. Remiss görs alltid i samförstånd
            med djurägaren. Journal kan även överföras till försäkringsbolag vid
            efterfrågan, detta görs då i samförstånd med djurägaren.
          </p>
          <p>
            Övriga företag får endast behandla personuppgifter enligt det
            personuppgiftsbiträdesavtal som tecknas med företaget och enligt de
            instruktioner som de får i samband med detta. De får inte använda
            dina personuppgifter för egna ändamål och de är skyldiga enligt lag
            och avtal att skydda dina personuppgifter. En tjänsteleverantör får
            inte dela dina personuppgifter med tredje part eller underleverantör
            utan att vi godkänner detta.
          </p>
          <h3>Myndigheter</h3>
          <p>
            Vi kan lämna nödvändig information till myndigheter om vi är
            skyldiga enligt lag att göra det. Denna information kan omfatta dina
            personuppgifter. I samband med en rättstvist kan det vara aktuellt
            att även överföra information som kan innehålla personuppgifter till
            övriga parter i tvisten.
          </p>
          <h2>Hur skyddas dina personuppgifter?</h2>
          <p>
            Vi skyddar dina personuppgifter genom en kombination av tekniska och
            organisatoriska lösningar. Vi har vidtagit särskilda
            säkerhetsåtgärder för att skydda dina personuppgifter mot olovlig
            eller obehörig åtkomst. Vi utarbetar rutiner och arbetssätt för att
            dina personuppgifter ska hanteras på ett säkert sätt. Endast de
            personer som faktiskt behöver behandla dina personuppgifter för sina
            arbetsuppgifter har tillgång till dem.
          </p>
          <h2>Dina rättigheter</h2>
          <p>Som registrerad hos oss har du följande rättigheter:</p>
          <ul>
            <li>
              Du har rätt att begära ett registerutdrag där du kan se vilka
              personuppgifter vi har om dig.
            </li>
            <li>
              Du har rätt att begära rättning om vi har felaktig eller
              ofullständig personuppgift registrerad om dig.
            </li>
            <li>
              Du har rätt att få dina personuppgifter raderade under dessa
              förutsättningar:
              <ul>
                <li>
                  Uppgifterna behövs inte längre för det ändamål de samlades in.
                </li>
                <li>
                  Om uppgifterna sparas med ditt samtycke och du återkallar
                  samtycket.
                </li>
                <li>
                  Om behandlingen grundar sig på en intresseavvägning och det
                  inte finns berättigade skäl som väger tyngre än ditt intresse.
                </li>
                <li>Om personuppgifterna har behandlats olagligt.</li>
                <li>
                  Om radering krävs för att uppfylla en rättslig skyldighet.
                </li>
                <li>
                  Om du invänder mot behandling för
                  direktmarknadsföringsändamål.
                </li>
              </ul>
              Rätten att få personuppgifter raderade gäller inte om vi är
              skyldiga enligt lag (t ex journalföring, bokföring) att behålla
              uppgifterna.
            </li>
            <li>
              Du har rätt att begära att behandlingen av dina personuppgifter
              begränsas. Om du begär en begränsning av din behandling av
              personuppgifter kan det dock medföra att vi inte kan uppfylla våra
              eventuella skyldigheter gentemot dig under den tid som
              begränsningen är gällande.
            </li>
            <li>
              Du har rätt att invända mot en personuppgiftsbehandling som har
              intresseavvägning som rättslig grund. För att vi ska kunna
              fortsätta med den aktuella behandlingen behöver vi kunna visa ett
              tvingande berättigat skäl för behandlingen som väger tyngre än
              dina intressen, rättigheter eller friheter. I annat fall får vi
              bara behandla uppgifterna för att fastställa, utöva eller försvara
              rättsliga anspråk.
            </li>
            <li>
              Du har alltid rätt att invända mot att dina personuppgifter
              används för direkt marknadsföring. Görs en invändning mot direkt
              marknadsföring, får personuppgifterna inte längre behandlas för
              sådana ändamål.
            </li>
          </ul>
          <p>
            <em>
              Om du inte är nöjd med det svar du har fått från oss har du rätt
              att lämna klagomål till tillsynsmyndigheten
              (Datainspektionen/Integritetsmyndigheten).
            </em>
          </p>
        </section>
      </oma-grid-row>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query {
    header: file(relativePath: { eq: "duo.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 800, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
